import React, { useState } from 'react'
import axios from 'axios'
import { Container, Form, Input, TextArea, Button} from 'semantic-ui-react'

const Contact = () => {
  const [contactForm, setContactForm] = useState({
    firstName: '', lastName: '', email: '', message: ''
  })

  const handleChange = (e) => {
    setContactForm({
      ...contactForm,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    axios({
      method: "POST",
      url: "/send",
      data: contactForm
    }).then((response) => {
      if (response.data.status === 'success'){
        alert("Message Sent.");
        resetForm()
      } else if (response.data.status === 'fail'){
        alert("Message failed to send.")
      }
    })    
  }

  const resetForm = () => {
    setContactForm({firstName: '', lastName: '', email: '', message: ''})
  }
  
  return(
    <div id="contact">
      <Container>
        <div className="contact">
          <h1 className="title">CONTACT</h1>
          <div className="bar"></div>
            <Form id="contact-form" onSubmit={handleSubmit} method="POST">
            <Form.Group widths="equal">
              <Form.Field 
                control={Input}
                label="First Name"
                placeholder="First Name"
                type="text"
                name="firstName"
                value={contactForm.firstName}
                onChange={handleChange}
                id="firstName"
                required
              />
              <Form.Field 
                control={Input}
                label="Last Name"
                placeholder="Last Name"
                type="text"
                name="lastName"
                value={contactForm.lastName}
                onChange={handleChange}
                id="lastName"
                required
              />
            </Form.Group>
            <Form.Field 
              control={Input}
              label="Email"
              placeholder="Email"
              type="email"
              name="email"
              value={contactForm.email}
              onChange={handleChange}
              id="email"
              required
            />
            <Form.Field 
              control={TextArea}
              label="Message"
              placeholder="Message"
              type="text"
              name="message"
              value={contactForm.message}
              onChange={handleChange}
              id="message"
              required
            />
            <Form.Group>
              <Form.Field 
                control={Button}
                content="Send"
                label="Send"
                type="submit"
              />
              <Form.Field 
                control={Button}
                content="Clear"
                label="Clear"
                onClick={resetForm}
              />
            </Form.Group>
          </Form> 
        </div>
      </Container>
    </div>
  )
}

export default Contact