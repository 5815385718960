import React from 'react'
import '../App.scss'
import Logo from '../media/logo.svg'

const Tagline = () => {
    return(
        <div className="tagline">
            <img src={Logo} alt="Logo" className="logo2" />
            <h1>Katrina Roaix</h1>
            <h3>Full Stack Developer</h3>
        </div>
    )
}

export default Tagline;