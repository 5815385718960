import React, { useState } from 'react'
import { Grid, Button, Header, Image, Modal } from 'semantic-ui-react'
import TidyHive from '../media/TidyHive.png'
import TidyHiveModal from '../media/TidyHiveModal.png'
import Replate from '../media/Replate.png'
import ReplateModal from '../media/ReplateModal.png'
import ConwaysGameofLife from '../media/ConwaysGameofLife.png'
import ConwaysGameofLifeModal from '../media/ConwaysGameofLifeModal.png'

const Portfolio = () => {
  const [firstOpen, setFirstOpen] = useState(false)
  const [secondOpen, setSecondOpen] = useState(false)
  const [thirdOpen, setThirdOpen] = useState(false)
  // const [fourthOpen, setFourthOpen] = useState(false)
  // const [fifthOpen, setFifthOpen] = useState(false)
  // const [sixthOpen, setSixthOpen] = useState(false)

  return(
    
    

    <div id="portfolio">
      <div className="portfolio">
        <h1 className="title">PORTFOLIO</h1>
        <div className="bar"></div>
        
        <Grid container stackable columns={3}>
          <Grid.Column>
          <Modal
              closeIcon
              onClose={() => setFirstOpen(false)}
              onOpen={() => setFirstOpen(true)}
              open={firstOpen}
              trigger={<Image src={TidyHive} />}
            >
              <Modal.Header>TidyHive</Modal.Header>
              <Modal.Content image>
                <Image size='medium' src={TidyHiveModal} />
                <Modal.Description>
                  <Header>About</Header>
                  <p>TidyHive simplifies home management with an easy-to-use interface and a robust feature set allowing you organize everything in your home from day-to-day tasks to larger projects.</p>
                  <p>TidyHive is a project I worked on with 6 additional team members.</p>
                  <p>This project was built over the course of 4 months part-time at approximately 3 hours per night using the Agile methodology.</p>
                  <p>TidyHive was featured #5 in <a href="https://careerkarma.com/blog/projects-spotlight-9-11-2020/" target="_blank" rel="noreferrer" >Career Karma's Project Spotlight 9-11-2020</a> and had received 84 upvotes. See the individual listing <a href="https://careerkarma.com/discussions/projects/tidy-hive-561/" target="_blank" rel="noreferrer" >here</a>.</p>
                  <Header>Tech Stack</Header>
                  <div className="techstack">
                    <div className="frontend">
                      <Header>Front End</Header>
                      <ul>
                        <li>React</li>
                        <li>Redux</li>
                        <li>Semantic-UI</li>
                        <li>React-Router</li>
                        <li>OAuth</li>
                        <li>Heroku</li>
                        <li>Sass</li>
                      </ul>  
                    </div>
                    <div className="backend">
                      <Header>Back End</Header>
                      <ul>
                        <li>Node.JS</li>
                        <li>Postgres</li>
                        <li>Knex</li>
                      </ul>  
                    </div>
                    <div className="testing">
                      <Header>Testing</Header>
                      <ul>
                        <li>Jest</li>
                        <li>React Testing Library</li>
                      </ul>  
                    </div>
                  </div>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black'
                  content="Source Code - Frontend"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://github.com/kroaix/homerun-fe','_blank')
                  }}
                />
                <Button color='black'
                  content="Source Code - Backend"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://github.com/kroaix/homerun-be','_blank')
                  }}
                />
                <Button
                  content="View Live"
                  labelPosition='right'
                  icon='arrow right'
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://tidyhive.vercel.app/','_blank')
                  }}
                  positive
                />
              </Modal.Actions>
            </Modal>
          </Grid.Column>
          <Grid.Column>
          <Modal
              closeIcon
              onClose={() => setSecondOpen(false)}
              onOpen={() => setSecondOpen(true)}
              open={secondOpen}
              trigger={<Image src={Replate} />}
            >
              <Modal.Header>Replate</Modal.Header>
              <Modal.Content image>
                <Image size='medium' src={ReplateModal} />
                <Modal.Description>
                  <Header>About</Header>
                    <p>Replate allows businesses with an overabundance of food to donate to those in need.</p>
                    <p>Replate is a project I worked on with 6 additional team members.</p>
                    <p>This project was built over the course of 2 weeks at approximately 3 hours per day.</p>
                    <Header>Tech Stack</Header>
                    <div className="techstack">
                      <div className="frontend">
                        <Header>Front End</Header>
                        <ul>
                          <li>HTML</li>
                          <li>CSS</li>
                          <li>JavaScript</li>
                          <li>React</li>
                          <li>Axios</li>
                          <li>Semantic-UI</li>
                        </ul>  
                      </div>
                      <div className="backend">
                        <Header>Back End</Header>
                        <ul>
                          <li>N/A</li>
                        </ul>  
                      </div>
                      <div className="testing">
                        <Header>Testing</Header>
                        <ul>
                          <li>N/A</li>
                        </ul>  
                      </div>
                    </div>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black'
                  content="Source Code"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://github.com/build-weeks-replate/Front-end/tree/master/replate','_blank')
                  }}
                />
                <Button
                  content="View Live"
                  labelPosition='right'
                  icon='arrow right'
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://replatewebpt7.netlify.com/','_blank')
                  }}
                  positive
                />
              </Modal.Actions>
            </Modal>
          </Grid.Column>
          <Grid.Column>
          <Modal
              closeIcon
              onClose={() => setThirdOpen(false)}
              onOpen={() => setThirdOpen(true)}
              open={thirdOpen}
              trigger={<Image src={ConwaysGameofLife} />}
            >
              <Modal.Header>Conway's Game of Life</Modal.Header>
              <Modal.Content image>
                <Image size='medium' src={ConwaysGameofLifeModal} />
                <Modal.Description>
                  <Header>About</Header>
                    <p>Conway's Game of Life is a week-long solo project.</p>
                    <p>This project utilizes what I had learned about algorithms and understanding the concept of Turing Completeness.</p>
                    <p>You can find all of the Rules of Conway's Game of Life in the App.</p>
                    <Header>Tech Stack</Header>
                    <div className="techstack">
                      <div className="frontend">
                        <Header>Front End</Header>
                        <ul>
                          <li>React</li>
                          <li>Semantic-UI</li>
                          <li>Immer</li>
                          <li>Sass</li>
                        </ul>  
                      </div>
                      <div className="backend">
                        <Header>Back End</Header>
                        <ul>
                          <li>N/A</li>
                        </ul>  
                      </div>
                      <div className="testing">
                        <Header>Testing</Header>
                        <ul>
                          <li>N/A</li>
                        </ul>  
                      </div>
                    </div>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black'
                  content="Source Code"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://github.com/kroaix/ConwaysGameofLife','_blank')
                  }}
                />
                <Button
                  content="View Live"
                  labelPosition='right'
                  icon='arrow right'
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://game-of-life-kroaix.vercel.app/','_blank')
                  }}
                  positive
                />
              </Modal.Actions>
            </Modal>
          </Grid.Column>
          <Header>More Coming Soon!</Header>
          {/* <Grid.Column>
          <Modal
              closeIcon
              onClose={() => setFourthOpen(false)}
              onOpen={() => setFourthOpen(true)}
              open={fourthOpen}
              trigger={<Image src='https://react.semantic-ui.com/images/wireframe/image.png' />}
            >
              <Modal.Header>Coming Soon</Modal.Header>
              <Modal.Content image>
                <Image size='medium' src='https://react.semantic-ui.com/images/avatar/large/rachel.png' wrapped />
                <Modal.Description>
                  <Header>Project 4</Header>
                  <p>About this project</p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => setFourthOpen(false)}>
                  Source Code
                </Button>
                <Button
                  content="View Live"
                  labelPosition='right'
                  icon='arrow right'
                  onClick={() => setFourthOpen(false)}
                  positive
                />
              </Modal.Actions>
            </Modal>
          </Grid.Column>
          <Grid.Column>
          <Modal
              closeIcon
              onClose={() => setFifthOpen(false)}
              onOpen={() => setFifthOpen(true)}
              open={fifthOpen}
              trigger={<Image src='https://react.semantic-ui.com/images/wireframe/image.png' />}
            >
              <Modal.Header>Project 5</Modal.Header>
              <Modal.Content image>
                <Image size='medium' src='https://react.semantic-ui.com/images/avatar/large/rachel.png' wrapped />
                <Modal.Description>
                  <Header>Project 5</Header>
                  <p>About this project</p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => setFifthOpen(false)}>
                  Source Code
                </Button>
                <Button
                  content="View Live"
                  labelPosition='right'
                  icon='arrow right'
                  onClick={() => setFifthOpen(false)}
                  positive
                />
              </Modal.Actions>
            </Modal>
          </Grid.Column>
          <Grid.Column>
          <Modal
              closeIcon
              onClose={() => setSixthOpen(false)}
              onOpen={() => setSixthOpen(true)}
              open={sixthOpen}
              trigger={<Image src='https://react.semantic-ui.com/images/wireframe/image.png' />}
            >
              <Modal.Header>Project 6</Modal.Header>
              <Modal.Content image>
                <Image size='medium' src='https://react.semantic-ui.com/images/avatar/large/rachel.png' wrapped />
                <Modal.Description>
                  <Header>Project 6</Header>
                  <p>About this project</p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => setSixthOpen(false)}>
                  Source Code
                </Button>
                <Button
                  content="View Live"
                  labelPosition='right'
                  icon='arrow right'
                  onClick={() => setSixthOpen(false)}
                  positive
                />
              </Modal.Actions>
            </Modal>
          </Grid.Column> */}
        </Grid>
      </div>
    </div>
  )
}

export default Portfolio