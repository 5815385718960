import React from 'react'
import '../App.scss'
import video from '../media/kroaix.mp4'

const Video = () => {
    return(
        <div className="video-container">
            <video loop autoPlay className="video" muted>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>        
    )
}

export default Video