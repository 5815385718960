import React from 'react'
import Logo from '../media/logo.svg'
import { Icon } from 'semantic-ui-react'

const Footer = () => {
  return(
    <div className="footer">
      <a href="#home"><div className="up"><Icon name="angle double up" size="large" /></div></a>
      <img src={Logo} alt="Logo" className="logo-footer" />
      <h5>Katrina Roaix | Full Stack Developer</h5>
      <a href="https://twitter.com/kroaix" target="blank"><Icon name="twitter" size="huge" /></a>
      <a href="https://www.linkedin.com/in/kroaix/" target="blank"><Icon name="linkedin" size="huge" /></a>
      <a href="https://github.com/kroaix" target="blank"><Icon name="github" size="huge" /></a>
      <h6>&copy; Copyright 2020 Katrina Roaix</h6>
    </div>
  )
}

export default Footer