import React from 'react'
import 'semantic-ui-css/semantic.min.css'
import Header from './components/Header'
import Video from './components/Video'
import About from './components/About'
import Portfolio from './components/Portfolio'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Tagline from './components/Tagline'


function App() {
  
  window.onscroll = function() {scrollFunction()};
  function scrollFunction() {
    if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
      document.getElementById("navbar").style.backgroundColor = "#333"
      document.getElementById("visible").style.visibility = "visible";
    } else {
      document.getElementById("navbar").style.backgroundColor = "transparent";
      document.getElementById("visible").style.visibility = "hidden";
    } 
    
  }
  
  return (
    <div>
      <Header />
      <Tagline />
      <Video />
      <About />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
