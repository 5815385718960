import React, { useState } from 'react'
import '../App.scss'
import Logo from '../media/logo.svg'
import { Icon } from 'semantic-ui-react'
import { useMediaQuery } from 'react-responsive'

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1001px)' })

  

  const openMenu = () => {
    setShowMenu(!showMenu)
    document.getElementById("navbar").style.height = "250px";
  } 
  const closeMenu = () => {
    setShowMenu(!showMenu)
    document.getElementById("navbar").style.height = "100px";
  }
    return(
        <div className="top" id="home">
          <nav id="navbar" className="mobile-navbar">
            <div><a href="#home"><p className="hidden-logo"><img src={Logo} alt="Logo" className="logo" />
            <span id="visible">Katrina Roaix<br />
            Full-Stack Developer</span></p></a></div>
            <div>{isTabletOrMobile && <>
              { !showMenu ? <Icon className="mobile-menu" name="bars" size="big" onClick={openMenu}/> : <Icon className="mobile-menu" name="close" size="big" onClick={closeMenu}/>}
              
              { showMenu ? <ul id="mobile-nav">
                <li><a href="#home">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                <li><a href="#contact">Contact</a></li>
              </ul> : null} </>}
              {isDesktop && <>
              <ul id="menu">
                <li><a href="#home">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                <li><a href="#contact">Contact</a></li>
              </ul></>}
            </div>
          </nav>
        </div>
    )
}

export default Header